@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;700&display=swap');


.App {
  color: #D6D5D5;
  font-family: figtree;
}

#hero-container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px; 
}

#hero-content{
  width: 80%;
  margin-bottom: 200px;
}

#my_intro__name{
  font-size: 1.5em;

}

#my_intro__description{
  font-size: 2.5em;
  margin-top: -7px;
}

#down-arrow-container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.fa-arrow-down{
  font-size: 3em;
  text-align: center;
}

#projects_title {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;

}

#projects_title > h1 {
  text-align: center;
}

#projects_container{
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.a_project{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}


.project_image_container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: inline-block;
}

.project_image{
  border-radius: 10px;
  height: 270px;
  width: 370px;
  /* width: 100px;
  height: 100px; */
  transition: filter 0.3s ease;
}

.project_image_container:hover .project_image{
  filter: brightness(20%);
}

.project_description{
  /* width: 70%;
  text-align: center; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
  z-index: 1;
  width: 80%;
  color: hsl(0, 1%, 66%);
  font-size: 1.2em;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project_image_container:hover .project_description {
  opacity: 1;
}

.github-button {
  font-family: 'Figtree, sans-serif';
  background-color: #D6D5D5;
  color: #18191B;
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.github-button:hover {
  background-color: #A9A8A8;
}

#education_title > h1 {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 35px;
}

.degree-title {
  text-align: center;
}

.education-institution {
  text-align: center;
}

.education-duration {
  text-align: center;
  margin-bottom: 30px;
}

#skills_title {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 30px;
}

.a-skill {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-container {
  display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 100px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7%;
    
}

.skill-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

#contacts-title {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 35px;
}

#contacts-container {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact_image {
  border-radius: 7px;
  font-size: 3em;
  color: #D6D5D5;
  transition: color 0.3s ease;
}

.contact_image:hover{
  color: #C44900;
}

#resume-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

#resume-button{
  font-family: 'Figtree, sans-serif';
  background-color: #D6D5D5;
  color: #18191B;
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#resume-button:hover {
  background-color: #A9A8A8;
}

#copyright {
  text-align: center;
}

@media screen and (max-width:1200px) {
  
  #projects_container{ 
    width: 100%;
  }

}



@media screen and (max-width:1000px) {
  
  #projects_container{ 
    gap: 30px;
  }

}


@media screen and (max-width:900px) {
  
  #projects_container{
    flex-direction: column;
    align-items: center;
  }
  
  .a_project{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  
  .project_image_container {
    position: static;
    display: block;
    display: flex;
    flex-direction: column;
  }
  
  .project_image {
    border-radius: 10px;
    height: 270px;
    width: 80%;
    transition: none;
    filter: none;
  }

  .project_image_container:hover .project_image{
    filter: none;
  }
  
  .project_description {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    text-align: center;
    z-index: initial;
    width: 80%;
    color: #A9A8A8;
    font-size: 1.2em;
    opacity: 1;
    transition: none;
  }

  .github-button {
    font-family: 'Figtree, sans-serif';
    background-color: #D6D5D5;
    color: #18191B;
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .github-button:hover {
    background-color: #A9A8A8;
  }
}

/* Mobile styles */
@media screen and (max-width:480px){

  .project_image {
    width: 100%;
  }

  .project_description {
    width: 100%;
  }
}

